<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="app layoutStore">
		<AppHeader
			:type="windowWidth === 'xs' ? 'compact': 'extended'"
			fixed>
			<b-link
				:class="{'left-position': (windowWidth === 'sm' || windowWidth === 'md')}"
				class="navbar-brand"
				to="/">
				<div
					:id="logoIds().navbar"
					class="navbar-brand-full d-none d-sm-block"
					alt="Logo" />
			</b-link>
			<b-navbar-nav class="ml-auto">
				<country-dropdown
					v-if="!this.$user.auth()"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mx-1' : ''" />
				<template v-else>
					<div :class="['xs', 'sm'].includes(windowWidth) ? 'mx-1' : ''">
						{{ country }}
						<img
							:src="getCountryFlag(country, true)"
							class="ml-1">
					</div>
				</template>
				<lang-dropdown :class="['xs', 'sm'].includes(windowWidth) ? 'mx-1' : 'mr-3 ml-4'" />
				<template v-if="!this.$user.auth()">
					|
					<button
						v-if="$route.name === 'PSStore' && !earlyLaunch"
						type="button"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left"
						@click="scrollTo('our-products')">
						<a
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-primary'"
							class="mb-4">
							<b :style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('buy_now').toUpperCase() }}
							</b>
						</a>
					</button>
					<button
						type="button"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left">
						<a
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-primary'"
							class="mb-4"
							@click="$router.push({ name: 'Register' })">
							<b :style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('join_now').toUpperCase() }}
							</b>
						</a>
					</button>
					<button
						type="button"
						:class="['xs', 'sm'].includes(windowWidth) ? 'px-2 mx-1' : ''"
						class="btn btn-link d-block text-left">
						<a
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'bolder-text-primary mr-3'"
							class="mb-4"
							@click="$router.push({ name: 'Login' })">
							<b :style="['xs', 'sm'].includes(windowWidth) ? 'font-size:.88em;' : ''">
								{{ translate('login') }}
							</b>
						</a>
					</button>
				</template>
				<template v-if="$user.auth()">
					<DefaultHeaderDropdownAccnt :show-dashboard="true" />
				</template>
			</b-navbar-nav>
		</AppHeader>
		<AppHeader
			v-if="!isCart && !isAdminOrCorporate && !earlyLaunch"
			:class="showDark()"
			:style="!isCart && setNavbarTop ? 'margin-top: 55px;' : ''"
			fixed
			class="border-0 store">
			<b-navbar-nav
				class="ml-sm-5 mr-auto">
				<!-- <button
					type="button"
					class="btn btn-link"
					@click="scrollTo('our-products')">
					<a
						class="font-weight-bold text-white">{{ translate('our_products') }}</a>
					<hr
						v-if="activeSection === 'our-products'"
						class="border-white m-0"
						style="width: 20px; border-width: 2px">
				</button> -->
				<!-- <button
					v-if="!['xs', 'sm', 'md'].includes(windowWidth)"
					type="button"
					class="btn btn-link">
					<a
						class="font-weight-bold text-white">{{ translate('contact_us') }}</a>
				</button>
				<button
					v-if="!['xs', 'sm', 'md'].includes(windowWidth)"
					type="button"
					class="btn btn-link">
					<a
						class="font-weight-bold text-white">{{ translate('blog') }}</a>
				</button> -->
			</b-navbar-nav>
			<b-navbar-nav
				ref="cartDropdownButton"
				:style="{ width: !['xs', 'sm'].includes(windowWidth) ? '380px' : '' }"
				class="h-100 mr-md-5">
				<button
					type="button"
					class="btn px-4 ml-auto shadow-none"
					@click="cartOpened = !cartOpened">
					<a
						class="font-weight-bold text-white"><i class="fas fa-shopping-cart mr-sm-3" />
						<span
							class="d-none d-sm-inline"
							v-html="itemsInCartLabel" />
						<span class="d-inline d-sm-none ml-2">( {{ items }} )</span>
					</a>
				</button>
			</b-navbar-nav>
			<div
				v-if="!['xs', 'sm', 'md'].includes(windowWidth) && showUpgradeProgress"
				ref="upgradeProgressDesktop"
				class="d-block position-absolute w-100 h-100"
				style="z-index: -2;">
				<div class="row no-gutters justify-content-center">
					<div class="col-auto">
						<div style="width: 35em">
							<div class="row h-100">
								<div class="col">
									<upgrade-progress
										:packages="upgradePackages"
										:current-volume="upgradeVolume"
										:checkout-button="productsHasData"
										card
										style="z-index: 1030" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppHeader>
		<AppHeader
			v-if="!showCartNavBar && !['xs', 'sm'].includes(windowWidth) && showUpgradeProgress"
			style="margin-top: 55px;"
			fixed
			class="border-0 store bg-transparent">
			<div
				ref="upgradeProgressDesktop"
				class="d-block position-absolute w-100 h-100">
				<div class="row no-gutters justify-content-center">
					<div class="col-auto">
						<div style="width: 35em">
							<div class="row h-100">
								<div class="col">
									<upgrade-progress
										:packages="upgradePackages"
										:current-volume="upgradeVolume"
										:checkout-button="productsHasData"
										card
										style="z-index: 10" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppHeader>
		<AppHeader
			v-if="['xs', 'sm', 'md'].includes(windowWidth) && showUpgradeProgress"
			ref="upgradeProgressMobile"
			class="d-block border-top-0 border-right-0 border-left-0 store"
			:style="{
				'margin-top': showCartNavBar ? '110px' : '55px',
				'height': 'auto',
			}"
			fixed>
			<b-navbar-nav class="d-block">
				<div class="row no-gutters h-100 w-100 justify-content-center px-2 py-1">
					<div class="col d-flex align-items-center mx-1">
						<upgrade-progress
							:current-volume="upgradeVolume"
							:packages="upgradePackages"
							:checkout-button="productsHasData"
							compact
							style="z-index: 10" />
					</div>
				</div>
			</b-navbar-nav>
		</AppHeader>
		<!-- BODY & FOOTER -->
		<div class="app-body">
			<template>
				<router-view :key="$route.fullPath" />
			</template>
		</div>
		<b-card-group
			id="about-us"
			class="aboutUsStore">
			<b-card
				class="border-0 rounded-0 bg-footer mb-0">
				<div
					class="row p-2 mx-auto mt-3 container">
					<div class="col">
						<div class="row">
							<div class="col-md-4 col-lg-5">
								<!-- Logo -->
								<div
									class="row"
									:class="['xs', 'sm'].includes(windowWidth) ? 'mb-4 text-center' : 'pb-4'">
									<div class="col">
										<img
											class="img-fluid footer-width"
											:class="['md'].includes(windowWidth) ? 'w-100' : ''"
											:src="`${S3_PATH}/assets/footer.png`">
									</div>
								</div>
								<!-- #LOVIT -->
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mb-3' : 'py-2 mt-2'"
									class="row text-white">
									<div class="col">
										<img
											:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
											class="img-fluid"
											:src="`${S3_PATH}/assets/lovit.png`">
									</div>
								</div>
								<!-- Social media -->
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mb-3' : 'py-2'"
									class="row text-white">
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'ml-auto' : ['md'].includes(windowWidth) ? 'pr-2' : ''"
										class="col-auto col-sm-auto">
										<a
											:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
											class="text-white font-weight-normal pointer h4"
											href="https://www.facebook.com/VelovitaOfficial/"
											target="_blank">
											<i class="fab fa-facebook-f" />
										</a>
									</div>
									<div class="col-auto col-sm-auto">
										<a
											:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
											class="text-white font-weight-normal pointer h4"
											href="https://vimeo.com/velovitaofficial"
											target="_blank">
											<i class="fab fa-vimeo" />
										</a>
									</div>
									<div class="col-auto col-sm-auto">
										<a
											:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
											class="text-white font-weight-normal pointer h4"
											href="https://www.linkedin.com/company/velovitaofficial/"
											target="_blank">
											<i class="fab fa-linkedin-in" />
										</a>
									</div>
									<div class="col-auto col-sm-auto">
										<a
											:class="['xs', 'sm', 'md'].includes(windowWidth) ? '' : 'mr-2'"
											class="text-white font-weight-normal pointer h4"
											href="https://www.instagram.com/velovita_official/"
											target="_blank">
											<i class="fab fa-instagram" />
										</a>
									</div>
									<div
										:class="['xs', 'sm'].includes(windowWidth) ? 'mr-auto' : ['md'].includes(windowWidth) ? 'pl-2' : ''"
										class="col-auto col-sm-auto">
										<a
											class="text-white font-weight-normal pointer h4"
											href="https://t.me/velovitaofficial"
											target="_blank">
											<i class="fab fa-telegram" />
										</a>
									</div>
								</div>
								<!-- Accepted payment methods -->
								<div
									:class="{ 'd-none' : ['sm', 'xs'].includes(windowWidth)}"
									class="row mb-4 py-4 mt-2">
									<div class="col-12">
										<div class="row">
											<div :class="'md' === windowWidth ? 'col-2' : 'col-auto pl-2'">
												<p
													:class="'md' === windowWidth ? 'h2' : 'h1'"
													class="white-transparency mb-0 text-center">
													<i class="fab fa-cc-amex" />
												</p>
											</div>
											<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
												<p
													:class="'md' === windowWidth ? 'h2' : 'h1'"
													class="white-transparency mb-0 text-center">
													<i class="fab fa-cc-visa" />
												</p>
											</div>
											<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
												<p
													:class="'md' === windowWidth ? 'h2' : 'h1'"
													class="white-transparency mb-0 text-center">
													<i class="fab fa-cc-mastercard" />
												</p>
											</div>
											<div :class="'md' === windowWidth ? 'col-2' : 'col-auto px-2'">
												<p
													:class="'md' === windowWidth ? 'h2' : 'h1'"
													class="white-transparency mb-0">
													<i class="fab fa-cc-discover" />
												</p>
											</div>
											<div
												:class="'md' === windowWidth ? 'col-2' : 'col-auto pr-2'"
												class="pr-2">
												<p
													:class="'md' === windowWidth ? 'h2' : 'h1'"
													class="white-transparency mb-0">
													<i class="fab fa-cc-diners-club" />
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-8 col-lg-7 d-flex flex-column">
								<div class="row">
									<div class="col-md-6">
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
											class="text-white">
											<p class="py-2 px-0 mb-0 headline-small">
												{{ translate('site') }}
											</p>
											<hr
												class="border-white ml-0 mt-0 mb-4"
												style="width: 50px; border-width: 1px">
										</div>
										<div class="row">
											<div class="col-md-6">
												<div
													:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
													class="pt-3">
													<a
														class="text-transparency pointer"
														:href="website">
														{{ translate('home') }}
													</a>
												</div>
												<div
													:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
													class="pt-3">
													<a
														class="text-transparency pointer"
														:href="`${website}/#products`">
														{{ translate('products') }}
													</a>
												</div>
												<div
													v-if="(isDistributor || isOpportunitySite)"
													:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''"
													class="pt-3">
													<a
														class="text-transparency pointer"
														target="_blank"
														:href="memberRewards">
														{{ translate('member_rewards') }}
													</a>
												</div>
											</div>
											<div class="col-md-6">
												<div
													:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
													class="pt-3">
													<a
														class="text-transparency pointer"
														:href="`${website}/about`">
														{{ translate('about') }}
													</a>
												</div>
												<div
													:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
													class="pt-3">
													<a
														class="text-transparency pointer"
														:href="`${website}/community`">
														{{ translate('community') }}
													</a>
												</div>
												<div
													v-if="isAdminOrCorporate || isDistributor"
													:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'd-none'"
													class="pt-3">
													<a
														class="text-transparency pointer"
														target="_blank"
														:href="policyAndProcedures">
														{{ translate('policies_and_procedures') }}
													</a>
												</div>
												<div
													v-if="isAdminOrCorporate || isDistributor"
													:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
													class="pt-3">
													<a
														class="text-transparency pointer"
														target="_blank"
														:href="policyAndProcedures">
														{{ translate('policies_and_procedures_alt') }}
													</a>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-lg ml-auto">
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'd-none' : ''"
											class="text-white">
											<p class="py-2 px-0 mb-0 headline-small">
												{{ translate('support') }}
											</p>
											<hr
												class="border-white ml-0 mt-0 mb-4"
												style="width: 50px; border-width: 1px">
										</div>
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'text-center mt-2' : ''"
											class="text-transparency pt-2">
											<a
												class="text-transparency"
												style="text-decoration: none;"
												:href="`mailto:${csEmail}`">
												<p>
													<i class="fas fa-envelope mr-1 text-white" /> {{ csEmail }}
												</p>
											</a>
											<p class="text-white mt-4">
												<i class="fas fa-headphones-alt mr-1" /> {{ translate('customer_support') }}
											</p>
											<p
												:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : ''">
												{{ translate('customer_schedule_1', {timezone:timezone}) }}
											</p>
											<p
												:class="['xs', 'sm'].includes(windowWidth) ? 'text-center' : 'mb-0'">
												{{ translate('customer_schedule_2', {timezone:timezone}) }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							:class="{ 'd-none' : ['md', 'lg', 'xl'].includes(windowWidth)}"
							class="row mb-4">
							<div class="col">
								<div class="row justify-content-center">
									<div class="col-2">
										<p class="white-transparency h1 mb-0 text-center">
											<i class="fab fa-cc-amex fa-sm" />
										</p>
									</div>
									<div class="col-2">
										<p class="white-transparency h1 mb-0 text-center">
											<i class="fab fa-cc-visa fa-sm" />
										</p>
									</div>
									<div class="col-2">
										<p class="white-transparency h1 mb-0 text-center">
											<i class="fab fa-cc-mastercard fa-sm" />
										</p>
									</div>
									<div class="col-2">
										<p class="white-transparency h1 mb-0">
											<i class="fab fa-cc-discover fa-sm" />
										</p>
									</div>
									<div class="col-2">
										<p class="white-transparency h1 mb-0">
											<i class="fab fa-cc-diners-club fa-sm" />
										</p>
									</div>
								</div>
							</div>
						</div>
						<hr
							class="border-transparency"
							style="border-width: 1px">
						<div
							:class="{ 'text-center' : ['xs', 'sm'].includes(windowWidth)}"
							class="row font-weight-light">
							<div class="col-12 col-md-4">
								<p class="small text-transparency">
									© {{ currentYear }} {{ companyName }} {{ translate('all_right_reserved') }}
								</p>
								<p
									v-if="ENTITY_MESSAGES_COUNTRIES.includes(country)"
									class="small text-transparency">
									<span class="white-transparency-title">{{ translate('payment_entity_title') }}</span>
									<br>{{ translate('payment_entity_text') }}
								</p>
							</div>
							<div class="col-12 col-md-8 captions disclaimer">
								{{ translate('disclaimer') }}
							</div>
						</div>
						<!-- <div class="row white-transparency font-weight-light text-center mt-4">
							<div class="col-12 captions">
								{{ translate('covid_disclaimer') }}
							</div>
						</div> -->
					</div>
				</div>
			</b-card>
		</b-card-group>
		<product-added-modal
			:loading="systemLoading"
			:cart-items="cartProducts"
			:cart-related-items="cartRelatedProducts"
			:totals="cartTotals"
			:points="cartPoints"
			:show-credits="showCredits"
			:small-text-totals="cartSmallTextTotals"
			:total-volume="totalVolume"
			:total="cartTotal"
			:qty-import="qty"
			:exchange="exchange.is_needed ? exchange.total : ''"
			:apply-free-bottle="applyFreeBottle"
			:free-bottle-message="freeBottleMessage"
			:possible-discount-messages="possibleDiscountMessages"
			:free-products-info="freeProductsInfo"
			:promotions="promotions"
			:show-messages="showMessages"
			@update-quantity="updateQuantity"
			@remove-product="removeProduct"
			@use-points="usePoints"
			@remove-points="removePoints" />
		<cart-sidebar
			:open="cartOpened"
			:loading="systemLoading"
			:cart-items="cartProducts"
			:totals="cartTotals"
			:points="cartPoints"
			:show-credits="showCredits"
			:small-text-totals="cartSmallTextTotals"
			:total-volume="totalVolume"
			:total="cartTotal"
			:qty-import="qty"
			:exchange="exchange.is_needed ? exchange.total : ''"
			:apply-free-bottle="applyFreeBottle"
			:free-bottle-message="freeBottleMessage"
			:possible-discount-messages="possibleDiscountMessages"
			:free-products-info="freeProductsInfo"
			:promotions="promotions"
			:show-messages="showMessages"
			@update-quantity="updateQuantity"
			@remove-product="removeProduct"
			@use-points="usePoints"
			@remove-points="removePoints"
			@open="getData"
			@close="cartOpened = false" />
	</div>
</template>

<script>
import Vue from 'vue';
import {
	Header as AppHeader,
} from '@coreui/vue';
import { sidebarCssClasses } from '@coreui/vue/src/shared/classes';
import VCalendar, { setupCalendar } from 'v-calendar';
import isLoading from '@/components/Loading';
import UpgradeProgress from '@/components/UpgradeProgress';
import CartData from '@/mixins/PhysicalStore/CartData';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import EarlyLaunch from '@/mixins/EarlyLaunch';
import Companies from '@/settings/Company';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { V_CALENDAR_CONFIG, RENEW_MEMBERSHIP_DAYS } from '@/settings/Dates';
import {
	CUSTOMER_SERVICE_EMAIL,
	TERMS_AND_CONDITIONS_REDIRECTION,
	TIMEZONE_CUSTOMER_SERVICE,
	MEMBER_REWARDS_REDIRECTION,
} from '@/settings/General';
import { S3_PATH } from '@/settings/Images';
import { URIS } from '@/settings/LiveChat';
import { ENTITY_MESSAGES_COUNTRIES } from '@/settings/Country';
import {
	admin as adminRoles, affiliate, customer, distributor,
} from '@/settings/Roles';
import {
	Store, Products, Grids, Purchase, Cart as CartMessages,
} from '@/translations';
import EventBus from '@/util/eventBus';
import CountryDropdown from '@/views/Layouts/Main/CountryDropdown';
import DefaultHeaderDropdownAccnt from '@/views/Layouts/Main/DefaultHeaderDropdownAccnt';
import LangDropdown from '@/views/Layouts/Main/LangDropdown';
import CartSidebar from './components/CartSidebar';
import ProductAddedModal from './components/ProductAddedModal';

Vue.component('isLoading', isLoading);
Vue.use(VCalendar, V_CALENDAR_CONFIG);

export default {
	name: 'StoreLayout',
	messages: [Store, Products, Grids, Purchase, CartMessages],
	components: {
		AppHeader,
		DefaultHeaderDropdownAccnt,
		LangDropdown,
		CountryDropdown,
		CartSidebar,
		ProductAddedModal,
		UpgradeProgress,
	},
	mixins: [CartData, DocumentTitle, WindowSizes, EarlyLaunch],
	data() {
		return {
			S3_PATH,
			appName: process.env.VUE_APP_NAME,
			poweredBy: process.env.VUE_APP_POWERED,
			activeSection: '',
			customer,
			Companies,
			renewMembershipDays: RENEW_MEMBERSHIP_DAYS,
			currentYear: new Date().getFullYear(),
			companyName: process.env.VUE_APP_COMPANY_NAME,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			isCart: false,
			timezone: TIMEZONE_CUSTOMER_SERVICE,
			csEmail: CUSTOMER_SERVICE_EMAIL,
			policyAndProcedures: TERMS_AND_CONDITIONS_REDIRECTION,
			memberRewards: MEMBER_REWARDS_REDIRECTION,
			website: process.env.VUE_APP_WEBSITE,
			scrolled: true,
			scrollValue: 0,
			cartOpened: false,
			setNavbarTop: false,
			openLiveChat: URIS.MAXIMIZE_CHAT,
			ENTITY_MESSAGES_COUNTRIES,
		};
	},
	computed: {
		name() {
			return this.$route.name;
		},
		itemsInCartLabel() {
			let qty = '<i class="fa fa-spinner fa-pulse"></i>';
			if (!this.systemLoading) {
				qty = this.productsHasData ? this.items : 0;
			}
			return this.translate('items_in_cart', { qty });
		},
		items() {
			let items = 0;
			if (this.productsHasData) {
				this.cartData.attributes.products.forEach((item) => {
					items += item.qty;
				});
			}
			return items;
		},
		showCredits() {
			return [distributor, affiliate, customer].includes(this.$user.details().type) && this.hasCredits;
		},
		isAdminOrCorporate() {
			return adminRoles.includes(this.$user.details().type);
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		isOpportunitySite() {
			return ['Opportunity1LandingPage', 'Opportunity2LandingPage'].includes(this.$route.name);
		},
		isUpgradeProgressAvailable() {
			return this.isDistributor && Number.isInteger(this.upgradeVolume) && this.hasUpgradePackages;
		},
		totalHeaderHeight() {
			// Init with main navbar height
			let totalHeight = 55;
			// Links an Cart navbar
			if (this.showCartNavBar) {
				totalHeight += 55;
			}
			// Upgrade Progress
			if (this.isUpgradeProgressAvailable) {
				totalHeight += ['xs', 'sm'].includes(this.windowWidth) ? 72 : 32;
			}
			return totalHeight;
		},
		showCartNavBar() {
			if (this.$route.name === 'PSCart') return false;
			return true;
		},
		showUpgradeProgress() {
			if (!this.isUpgradeProgressAvailable) return false;
			if (this.$route.name === 'PSStore') return this.activeSection === 'our-products';
			return true;
		},
	},
	watch: {
		cartOpened(newVal) {
			const body = document.getElementsByTagName('body')[0];
			if (newVal) {
				body.classList.add('overflow-hidden');
			} else {
				body.classList.remove('overflow-hidden');
			}
		},
		language(newVal) {
			this.changeDocumentTitle();
			setupCalendar({
				locale: newVal,
			});
		},
		scrollValue(newVal) {
			this.activeSection = '';
			if (document.getElementById('our-products') !== null && newVal >= document.getElementById('our-products').offsetTop - this.totalHeaderHeight) {
				this.activeSection = 'our-products';
			}
		},
		async country(newVal, oldValue) {
			if (oldValue !== null) {
				await this.renewCart();
			} else if (oldValue === null && !this.cartId) {
				await this.createNewCart();
			}
			// Due to importing two different versions of VSG's script, we need to reload the page when
			// 	a different country is selected to reset external script imports.
			// TODO:
			// 	- Perhaps we can limit this to only detect changes between EU and non-EU countries
			// 	- Remove this after transitioning to single version of credit card payments

			window.location.reload();
		},
	},
	created() {
		if (this.name === 'PSCart') {
			this.isCart = true;
		}
		setupCalendar({ locale: this.language });
		sidebarCssClasses.map((item) => document.body.classList.remove(item));
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape' && this.cartOpened) {
				this.cartOpened = false;
			}
		});
		EventBus.$on('storeViewMounted', () => {
			if (this.$route.query.option === 'our-products') {
				this.scrollTo('our-products');
			}
		});
		EventBus.$on('scrollTo', (id) => {
			this.scrollTo(id);
		});
	},
	mounted() {
		EventBus.$on('updateLayoutStoreTotals', () => {
			this.getCartTotals();
		});
		this.setCartCookie(this.$cookie.get('cart_id'), { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` }, false);
		this.getData();
		if (this.$user.details().type === distributor) {
			this.getUpgradePackagesInfo();
		}
	},
	destroyed() {
		window.removeEventListener('scroll', () => {});
	},
	beforeDestroy() {
		EventBus.$off('updateLayoutStoreTotals');
		EventBus.$off('storeViewMounted');
		EventBus.$off('scrollTo');
	},
	methods: {
		handleScroll() {
			this.setNavbarTop = window.scrollY > 10;
			this.scrolled = window.scrollY > -10;
			this.scrollValue = window.scrollY;
		},
		scrollTo(id) {
			if (id === 'our-products' && this.$route.name !== 'PSStore') {
				this.$router.push({
					name: 'PSStore',
					query: {
						option: 'our-products',
					},
				});
			}
			this.$nextTick(() => {
				const element = document.getElementById(id);
				if (element) {
					const elementPosition = element.getBoundingClientRect().top;
					const offsetPosition = window.pageYOffset + elementPosition - this.totalHeaderHeight;
					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth',
					});
				}
			});
		},
		getData() {
			if (this.$cookie.get('cart_id') !== null) {
				this.getCartTotals();
			} else if (this.$cookie.get('cart_id') === null) {
				setTimeout(() => {
					this.getCartTotals();
				}, 2000);
			}
		},
		showDark() {
			if (this.windowWidth !== 'xs') {
				if (this.$route.name === 'PSProductDetails') return 'bg-dark';
				return this.scrolled ? 'bg-dark' : 'bg-transparent';
			}
			return 'bg-dark';
		},
		getCountryFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (vm.$user.details().force_username_change) {
				vm.$router.replace({ name: 'ForceUsernameChange' });
			} else if (vm.$user.details().remaining_days_terminated === vm.renewMembershipDays && vm.$user.details().type === distributor) {
				vm.$router.replace({ name: 'MembershipRenewal' });
			} else if (vm.$user.details().pay_transfer_account_fee) {
				vm.$router.replace({ name: 'AccountTransferFee' });
			}
			if (vm.name === 'PSStore' && from.name === 'PSCart') {
				try {
					vm.getData();
				} catch (error) {
					return {};
				}
			}
			return null;
		});
	},
	beforeRouteLeave(to, from, next) {
		this.cartOpened = false;
		if (to.name === 'PSCart') {
			this.isCart = true;
		} else {
			this.isCart = false;
		}
		this.alert.close();
		next();
	},
};
</script>

<style scoped>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	p {
		font-family: 'Montserrat', sans-serif !important;
	}
	.layoutStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	.aboutUsStore {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	.app-header.navbar.store {
		margin-top: 55px;
		z-index: 1010;
	}
	.app-header.navbar.store {
		margin-top: 55px;
		z-index: 1010;
	}
	.white-transparency-title {
		color: #f1f1f1;
	}
	.white-transparency {
		color: #6c6c6c7a;
	}
	.text-transparency {
		color: #ffffffad;
	}
	.disclaimer{
		color:#828282;
	}
	.border-transparency {
		border-color: #6c6c6cbf;
	}
	.bg-footer {
		background-color:#2b2b2b;
	}
	.footer-width {
		max-width: 265px;
	}
</style>
