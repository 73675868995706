import IsLoading from '@/components/Loading';
import QuantitySelect from '@/components/QuantitySelect';
import PossibleDiscounts from '@/components/PossibleDiscounts';
import WindowSizes from '@/mixins/WindowSizes';
import { PRODUCT_NON_CHANGEABLE_QTYS } from '@/settings/Products';
import {
	Cart as CartMessages, Store, Products, Purchase,
} from '@/translations';

export default {
	messages: [CartMessages, Store, Products, Purchase],
	components: { IsLoading, QuantitySelect, PossibleDiscounts },
	mixins: [WindowSizes],
	props: {
		cartItems: {
			type: Array,
			default: () => [],
		},
		total: {
			type: String,
			default: '',
		},
		totalVolume: {
			type: String,
			default: '',
		},
		totals: {
			type: Object,
			default: () => ({}),
		},
		points: {
			type: Object,
			default: () => ({}),
		},
		smallTextTotals: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		qtyImport: {
			type: Object,
			default: () => ({}),
		},
		exchange: {
			type: String,
			default: '',
		},
		applyFreeBottle: {
			type: Boolean,
			default: false,
		},
		freeBottleMessage: {
			type: String,
			default: '',
		},
		cartRelatedItems: {
			type: Array,
			default: () => [],
		},
		possibleDiscountMessages: {
			type: Array,
			default: () => [],
		},
		promotions: {
			type: Array,
			default: () => [],
		},
		showMessages: {
			type: Boolean,
			default: false,
		},
		freeProductsInfo: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			blackList: PRODUCT_NON_CHANGEABLE_QTYS,
			qty: {},
		};
	},
	computed: {
		totalsHasData() {
			return Object.keys(this.totals).length !== 0;
		},
		cartItemsHasData() {
			return this.cartItems.length !== 0;
		},
		cartRelatedItemsHasData() {
			return this.cartRelatedItems.length !== 0;
		},
	},
	watch: {
		qtyImport: {
			deep: true,
			handler(newVal) {
				this.qty = JSON.parse(JSON.stringify(newVal));
			},
			immediate: true,
		},
	},
	methods: {
		updateQuantity(qty, product) {
			this.$emit('update-quantity', qty, product);
		},
		removeProduct(e, product) {
			this.$emit('remove-product', product);
		},
		clickCheckPoints(e, product) {
			let emit = 'use-points';
			if (product.use_points) {
				emit = 'remove-points';
			}
			this.$emit(emit, { sku: product.sku });
		},
	},
};
